import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Box,
    Typography,
    IconButton,
    Paper,
    List,
    ListItem,
    ListItemText,
    CircularProgress,
    Tooltip,
    TextField,
    Button,
    useMediaQuery,
    Divider,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import PhoneIcon from '@mui/icons-material/Phone';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';

const Chatbot = () => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const [mainMessages, setMainMessages] = useState([]);
    const [mainInput, setMainInput] = useState('');
    const [isMainTyping, setIsMainTyping] = useState(false);
    const mainMessagesEndRef = useRef(null);
    const recognitionRef = useRef(null);
    const [isListening, setIsListening] = useState(false);
    const inputRef = useRef(null);

    useEffect(() => {
        if (mainMessages.length > 0) {
            mainMessagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [mainMessages]);

    useEffect(() => {
        if (!isMainTyping) {
            inputRef.current?.focus();
        }
    }, [isMainTyping]);

    const handleSendMessage = async () => {
        if (!mainInput.trim()) return;
        const userMessage = { sender: 'user', text: mainInput };
        setMainMessages((prev) => [...prev, userMessage]);
        setMainInput('');
        setIsMainTyping(true);
        const headers = {};
        headers['Content-Type'] = 'application/json';
        axios.defaults.withCredentials = true;

        try {
                const response = await axios.post('https://support.kudiltech.com/support/ask-myna', {
                query: mainInput,
                languageCode: 'en-US',
                agentName: 'kudiltech'
            });
            const botMessage = { sender: 'bot', text: response.data.response };
            setMainMessages((prev) => [...prev, botMessage]);
        } catch (error) {
            console.error('API Error:', error);
            setMainMessages((prev) => [...prev, { sender: 'bot', text: 'Something went wrong. Try again later.' }]);
        } finally {
            setIsMainTyping(false);
        }
    };

    return (
        <Box sx={{ width: '100%', height: '100vh', display: 'flex', flexDirection: 'column', overflow: 'hidden', position: 'relative' }}>
            {/* Header */}
            <Box sx={{ flexShrink: 0, padding: 2, backgroundColor: '#4C4F6D', color: 'white', textAlign: 'center', position: 'relative' }}>
                <Typography variant="h6">Customer Service Chat</Typography>
                   <Button href="/" variant="contained" color="secondary" sx={{ position: 'absolute', right: 35, top: '50%', transform: 'translateY(-50%)', textTransform: 'none', borderRadius: 3, padding: '6px 12px', fontSize: '10px', backgroundColor: '#ff4081', '&:hover': { backgroundColor: '#e91e63' } }}>
                        Home
                    </Button>
            </Box>

            {/* Chat Layout */}
            <Box sx={{ display: 'flex', flex: 1, overflow: 'hidden', flexDirection: isMobile ? 'column' : 'row' }}>
                {/* Sidebar with Phone Icon */}
                <Box sx={{ width: isMobile ? '100%' : '80px', backgroundColor: '#2C3E50', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: 2, flexShrink: 0, height: isMobile ? 'auto' : '100%' }}>
                    <Tooltip title="Call us" arrow>
                        <IconButton sx={{ fontSize: isMobile ? 30 : 40, backgroundColor: '#4CAF50', color: 'white', borderRadius: '50%', '&:hover': { backgroundColor: '#45a049' } }} onClick={() => window.location.href = 'tel:+15043567626'}>
                            <PhoneIcon fontSize="inherit" />
                        </IconButton>
                    </Tooltip>
                </Box>

                {/* Main Chatbot */}
                <Paper elevation={3} sx={{ flex: 1, display: 'flex', flexDirection: 'column', backgroundColor: '#ECF0F1', overflow: 'hidden' }}>
                    <Box sx={{ flex: 1, padding: 2, overflowY: 'auto', minHeight: 0 }}>
                        <List>
                            {mainMessages.map((msg, index) => (
                                <ListItem key={index} sx={{ justifyContent: msg.sender === 'user' ? 'flex-start' : 'flex-end' }}>
                                    <Box sx={{ maxWidth: '70%', padding: 1, borderRadius: 2, backgroundColor: msg.sender === 'user' ? '#D3E9FF' : '#BFD8E9', color: 'black' }}>
                                        <ListItemText primary={msg.text} />
                                    </Box>
                                </ListItem>
                            ))}
                            {isMainTyping && (
                                <ListItem sx={{ justifyContent: 'flex-end' }}>
                                    <CircularProgress size={20} />
                                    <Typography sx={{ ml: 1 }}>Typing...</Typography>
                                </ListItem>
                            )}
                        </List>
                        <div ref={mainMessagesEndRef} />
                    </Box>

                    {/* Chat Input Section */}
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', padding: 1, borderTop: '1px solid #ccc', backgroundColor: '#F1F1F1', position: 'relative', bottom: isMobile ? 15 : 10, width: '100%' }}>
                        <Tooltip title={isListening ? "Stop Listening" : "Start Listening"} arrow>
                            <IconButton color={isListening ? "error" : "primary"} onClick={() => setIsListening(!isListening)} disabled={isMainTyping}>
                                <MicIcon />
                            </IconButton>
                        </Tooltip>
                        <TextField fullWidth variant="outlined" placeholder="Type a message..." inputRef={inputRef}
                            value={mainInput} onChange={(e) => setMainInput(e.target.value)} onKeyDown={(e) => e.key === 'Enter' && !isMainTyping && handleSendMessage()} disabled={isMainTyping} />
                        <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ marginLeft: 1 }} disabled={isMainTyping}>
                            <SendIcon />
                        </Button>
                    </Box>
                </Paper>
            </Box>

            {/* Global Footer */}
            <Box sx={{ flexShrink: 0, padding: 2, backgroundColor: '#4C4F6D', color: 'white', textAlign: 'center' }}>
                <Typography variant="body2">This AI conversation is powered by KudilTech.</Typography>
            </Box>
        </Box>
    );
};

export default Chatbot;
